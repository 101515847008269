/*
    DO = untuk melakukan perintah tanpa call API
    POST = untuk post tanpa save
    GET = untuk mengambil data
    CREATE = untuk menyimpan data
    UPDATE = untuk update data
    DELETE = untuk hapus data
*/

//AUTH
export const POST_LOGIN_REQUEST = "POST_LOGIN_REQUEST";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";
export const POST_LOGIN_FAIL = "POST_LOGIN_FAIL";
export const DO_LOGOUT = "DO_LOGOUT";

//HOME
export const GET_DASHBOARD_REQUEST = "GET_DASHBOARD_REQUEST";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAIL = "GET_DASHBOARD_FAIL";

//USER
export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";
//USER_CREATE
export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";
//USER_UPDATE
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
//USER_DELETE
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

//ROLE
export const GET_ROLES_REQUEST = "GET_ROLES_REQUEST";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAIL = "GET_ROLES_FAIL";
//ROLE_CREATE
export const CREATE_ROLES_REQUEST = "CREATE_ROLE_REQUEST";
export const CREATE_ROLES_SUCCESS = "CREATE_ROLES_SUCCESS";
export const CREATE_ROLES_FAIL = "CREATE_ROLES_FAIL";
//ROLE_DELETE
export const DELETE_ROLES_REQUEST = "DELETE_ROLE_REQUEST";
export const DELETE_ROLES_SUCCESS = "DELETE_ROLES_SUCCESS";
export const DELETE_ROLES_FAIL = "DELETE_ROLES_FAIL";

//CATEGORY
export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";
export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL";
//CATEGORY_CREATE
export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAIL = "CREATE_CATEGORY_FAIL";
//CATEGORY_UPDATE
export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL";
//CATEGORY_DELETE
export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";

//PRODUCT
export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";
export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAIL = "GET_PRODUCT_FAIL";
//PRODUCT_CREATE
export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAIL = "CREATE_PRODUCT_FAIL";
//PRODUCT_UPDATE
export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";
//PRODUCT_DELETE
export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";

//TRANSACTION
export const GET_TRANSACTIONS_REQUEST = "GET_TRANSACTIONS_REQUEST";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAIL = "GET_TRANSACTIONS_FAIL";
export const GET_TRANSACTION_REQUEST = "GET_TRANSACTION_REQUEST";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_FAIL = "GET_TRANSACTION_FAIL";
//TRANSACTION_CREATE
export const CREATE_TRANSACTION_REQUEST = "CREATE_TRANSACTION_REQUEST";
export const CREATE_TRANSACTION_SUCCESS = "CREATE_TRANSACTION_SUCCESS";
export const CREATE_TRANSACTION_FAIL = "CREATE_TRANSACTION_FAIL";
//TRANSACTION_UPDATE
export const UPDATE_TRANSACTION_REQUEST = "UPDATE_TRANSACTION_REQUEST";
export const UPDATE_TRANSACTION_SUCCESS = "UPDATE_TRANSACTION_SUCCESS";
export const UPDATE_TRANSACTION_FAIL = "UPDATE_TRANSACTION_FAIL";
//TRANSACTION_DELETE
export const DELETE_TRANSACTION_REQUEST = "DELETE_TRANSACTION_REQUEST";
export const DELETE_TRANSACTION_SUCCESS = "DELETE_TRANSACTION_SUCCESS";
export const DELETE_TRANSACTION_FAIL = "DELETE_TRANSACTION_FAIL";

//BANK
export const GET_BANKS_REQUEST = "GET_BANKS_REQUEST";
export const GET_BANKS_SUCCESS = "GET_BANKS_SUCCESS";
export const GET_BANKS_FAIL = "GET_BANKS_FAIL";
export const GET_BANK_REQUEST = "GET_BANK_REQUEST";
export const GET_BANK_SUCCESS = "GET_BANK_SUCCESS";
export const GET_BANK_FAIL = "GET_BANK_FAIL";
//BANK_CREATE
export const CREATE_BANK_REQUEST = "CREATE_BANK_REQUEST";
export const CREATE_BANK_SUCCESS = "CREATE_BANK_SUCCESS";
export const CREATE_BANK_FAIL = "CREATE_BANK_FAIL";
//BANK_UPDATE
export const UPDATE_BANK_REQUEST = "UPDATE_BANK_REQUEST";
export const UPDATE_BANK_SUCCESS = "UPDATE_BANK_SUCCESS";
export const UPDATE_BANK_FAIL = "UPDATE_BANK_FAIL";
//BANK_DELETE
export const DELETE_BANK_REQUEST = "DELETE_BANK_REQUEST";
export const DELETE_BANK_SUCCESS = "DELETE_BANK_SUCCESS";
export const DELETE_BANK_FAIL = "DELETE_BANK_FAIL";

//FILE
export const GET_FILES_REQUEST = "GET_FILES_REQUEST";
export const GET_FILES_SUCCESS = "GET_FILES_SUCCESS";
export const GET_FILES_FAIL = "GET_FILES_FAIL";
export const GET_FILE_REQUEST = "GET_FILE_REQUEST";
export const GET_FILE_SUCCESS = "GET_FILE_SUCCESS";
export const GET_FILE_FAIL = "GET_FILE_FAIL";
//FILE_CREATE
export const CREATE_FILE_REQUEST = "CREATE_FILE_REQUEST";
export const CREATE_FILE_SUCCESS = "CREATE_FILE_SUCCESS";
export const CREATE_FILE_FAIL = "CREATE_FILE_FAIL";
//FILE_UPDATE
export const UPDATE_FILE_REQUEST = "UPDATE_FILE_REQUEST";
export const UPDATE_FILE_SUCCESS = "UPDATE_FILE_SUCCESS";
export const UPDATE_FILE_FAIL = "UPDATE_FILE_FAIL";
//FILE_DELETE
export const DELETE_FILE_REQUEST = "DELETE_FILE_REQUEST";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAIL = "DELETE_FILE_FAIL";

//REPLICATE
export const GET_REPLICATES_REQUEST = "GET_REPLICATES_REQUEST";
export const GET_REPLICATES_SUCCESS = "GET_REPLICATES_SUCCESS";
export const GET_REPLICATES_FAIL = "GET_REPLICATES_FAIL";
export const GET_REPLICATE_REQUEST = "GET_REPLICATE_REQUEST";
export const GET_REPLICATE_SUCCESS = "GET_REPLICATE_SUCCESS";
export const GET_REPLICATE_FAIL = "GET_REPLICATE_FAIL";
//REPLICATE_CREATE
export const CREATE_REPLICATE_REQUEST = "CREATE_REPLICATE_REQUEST";
export const CREATE_REPLICATE_SUCCESS = "CREATE_REPLICATE_SUCCESS";
export const CREATE_REPLICATE_FAIL = "CREATE_REPLICATE_FAIL";
//REPLICATE_UPDATE
export const UPDATE_REPLICATE_REQUEST = "UPDATE_REPLICATE_REQUEST";
export const UPDATE_REPLICATE_SUCCESS = "UPDATE_REPLICATE_SUCCESS";
export const UPDATE_REPLICATE_FAIL = "UPDATE_REPLICATE_FAIL";
//REPLICATE_DELETE
export const DELETE_REPLICATE_REQUEST = "DELETE_REPLICATE_REQUEST";
export const DELETE_REPLICATE_SUCCESS = "DELETE_REPLICATE_SUCCESS";
export const DELETE_REPLICATE_FAIL = "DELETE_REPLICATE_FAIL";
