import './App.css';
import Router from './router';
import React from 'react';

function App() {
  return (

    <Router/>

  );
}

export default App;
